<template>
  <div class="cabecera-contenido">
    <form @submit.prevent="mostrarModal" class="resultados-casilla">
      <h3>Conoce los resultados de tu casilla</h3>
      <div>
        <p>
          <font-awesome-icon
          icon="question-circle"
          v-b-modal.ConoceTuCredencial
          class="icono"
        />
          Para conocer los resultados de tu casilla, ingresa los siguientes
          datos
          <br />
          <sup>*</sup> Campo obligatorio
        </p>
      </div>
      <div class="grilla-form">
        <div>
          <h4>
            Sección
            <sup>*</sup>
          </h4>
          <input v-model="seccionInput" maxlength="4" required />
        </div>
      </div>
      <button class="boton-rosa">
        Consulta
      </button>
    </form>
    <div class="avance-entidad">
      <div>
        <h2>Avance Entidad</h2>
        <button class="boton-actualizar" ref="" @click="recargarPagina">
          <font-awesome-icon icon="redo" />
          <span>Actualizar</span>
        </button>
      </div>
      <div>
        <ul>
          <li class="actas-capturadas">
            <div>
              <h3>Actas capturadas</h3>
              <div>
                <div>
                  <strong>{{
                    avance.actas_capturadas_numero
                  }}</strong>
                  de {{ avance.actas_esperadas_numero }}
                </div>
                <div>
                  <span
                    >({{ avance.actas_capturadas_porcentaje }}%)</span
                  >
                </div>
              </div>

              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  :style="`width: ${avance.actas_capturadas_porcentaje}%`"
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </li>
          <li class="participacion-ciudadana">
            <div>
              <div>
                <h3>
                  <a v-b-modal.ConocePorcentajeParticipacionCiudadana>
                    <font-awesome-icon icon="question-circle" />
                  </a>
                  Participación Ciudadana
                </h3>
              </div>

              <div>
                <span>
                  {{ avance.participacion_ciudadana_porcentaje }}%
                </span>
              </div>
            </div>
          </li>
          <li class="ultimo-corte">
            <div>
              <div>
                <h3>Último corte</h3>
              </div>
              <div>
                <strong>{{ avance.fecha_ultimo_corte }}</strong>
                <div>
                  <span>hora local</span>
                  <span>{{ avance.hora_local }}</span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'CabeceraContenido',
  props: {
    avance: Object,
  },
  setup(_, context) {
    const seccionInput = ref(null);

    const mostrarModal = () => {
      context.emit('mostrarModal', seccionInput.value);
    };

    const recargarPagina = () => {
      window.location.reload();
    };

    return {
      seccionInput,
      mostrarModal,
      recargarPagina,
    };
  },
});
</script>

<style lang="scss" scoped>
.grilla-form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;

  div {
    width: 97% !important;
  }
}
</style>

