<template>
  <div class="cuerpo">
    <!-- <div class="menu-izquierda" :class="{ colapsado: colapsado }"> -->
    <div class="menu-izquierda">
      <div v-if="!esVotoEnElExtranjero" >
        <div class="menu-filtros" v-if="colapsado">
          <button class="boton-regresar"  @click="colapsado = !colapsado">
            <font-awesome-icon icon="arrow-left" />
            <span>
              Regresar
            </span>
          </button>
        </div>
        <div
          v-else
          class="menu-filtros"
          :class="
            this.$route.name == 'BaseDatos'
            || this.$route.name == 'PreguntasFrecuentes' ? 'ocultar' : ''
          "
        >
          <h3>Consulta de votación</h3>
          <div v-if="!esSeccion">
            <p>Selecciona el reporte que deseas consultar:</p>
            <ul>
              <li :class="{ activo: esVotoPorCandidatura }">
                <router-link :to="`/${this.$route.params.eleccion}/ENT/VPC`">
                  <span v-if="this.$route.params.eleccion === 'G'
                  || this.$route.params.distrito != null">
                    Votos por Candidatura
                  </span>
                  <span v-else-if="this.$route.params.eleccion === 'D'">
                    Votos por Distritos
                  </span>
                  <span v-else> Votos por Municipios </span>
                </router-link>
              </li>
              <li :class="{ activo: esVotoPorPartido }">
                <router-link :to="`/${this.$route.params.eleccion}/ENT/VPP`">
                  Votos por Partido Político, Candidatura Independiente y Candidatura Común
                </router-link>
              </li>
              <li
                v-if="this.distrito !== null && eleccion !== 'A'"
                :class="{ activo: esDetallePorCasilla }"
              >
                <router-link :to="`/${eleccion}/DPC/${this.$route.params.distrito}`">
                  Detalle por Casilla
                </router-link>
              </li>
              <!--
              <li :class="{ activo: esSeccion }" >
                <router-link :to="`/${eleccion}/Secciones/1`">
                  Secciones
                </router-link>
              </li>-->
            </ul>
          </div>

          <div v-else>
            <p>Selecciona el reporte que deseas consultar:</p>
          </div>

          <div class="cajas-filtros">
            <h2>Detalle por:</h2>
            <h4 v-if="eleccion !== 'A'">Distrito</h4>
            <h4 v-else>Municipio</h4>
            <select v-if="eleccion !== 'A'" v-model="distrito" @change="cambiarVista" class="mb-3">
              <option :value="null">Todos</option>
              <option
                :value="region.id"
                v-for="(region, indice) in listaDistritos"
                :key="indice"
              >
                {{ region.id }}. {{ region.descripcion }}
              </option>
            </select>
            <select v-else v-model="distrito" @change="cambiarVista" class="mb-3">
              <option :value="null">Todos</option>
              <option
                :value="municipio.municipio_id"
                v-for="(municipio, indice) in listaMunicipios"
                :key="indice"
              >
                {{ municipio.municipio_id }}. {{ municipio.municipio_descripcion }}
              </option>
            </select>

            <h4 v-if="esSeccion || seccion !== '' || distrito !== null">
              Sección
            </h4>
            <select
              v-model="seccion"
              @change="irADetalleSeccion()"
              v-if="
                esSeccion || seccion !== '' || seccion === 0 ||
                seccion === 'todas' || distrito !== null
              "
              class="mb-3"
            >
              <option value=""></option>
              <option value="0">Todas</option>
              <option
                v-for="(seccion, index) in listaSecciones"
                :key="index"
                :value="seccion.seccion_id"
              >
                {{seccion.seccion_id}}
                </option>
            </select>
            <div
              v-if="
                this.$router.currentRoute.name === 'SeccionCasilla' ||
                  this.$router.currentRoute.name === 'DetalleSeccion'
              "
              class="mb-3"
            >
              <h4>Casilla</h4>
              <select v-model="casilla" @change="irADetalleCasilla">
                <option :value="null">Todos</option>
                <option v-for="(casilla, index) in listaCasillas.datos_casillas"
                :key="index"
                :value="casilla.tipo_casilla">{{casilla.tipo_casilla}}</option>
              </select>
            </div>
          </div>
        </div>
        <div
          class="menu_base_de_datos"
          :class="this.$route.name == 'BaseDatos' ? '' : 'ocultar'"
        >
          <div>
            <h3 class="titulo-borde-izq">Menú</h3>
            <div class="lista_menu_bd">
              <ul>
                <li>
                  <h4>Definición</h4>
                  <ul>
                    <a href="#BaseDeDatos"><li>Base de datos y utilidad</li></a>
                  </ul>
                </li>
                <li>
                  <h4>Carpeta de archivos</h4>
                  <ul>
                    <a href="#Contenido"><li>Contenido</li></a>
                    <a href="#Descargar"><li>Descargar</li></a>
                    <a href="#Descomprimir"><li>Descomprimir</li></a>
                    <a href="#Abrir"><li>Abrir</li></a>
                  </ul>
                </li>
                <li>
                  <h4>Consulta</h4>
                  <ul>
                    <a href="#FormatoCeldas"><li>Formato de celdas</li></a>
                    <a href="#InterpretacionBaseDatos"><li>Interpretacion de base de datos</li></a>
                    <a href="#Autenticidad"><li>Autenticidad</li></a>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div class="descargar">
            <h4>Descargar</h4>

            <a
              class="boton-azul"
              :href="elementosGenerales.url_descargar_CSV"
            >
              Base de datos
              <font-awesome-icon icon="download"/>
            </a>
          </div>
        </div>
        <div
          class="menu_base_de_datos"
          :class="this.$route.name == 'PreguntasFrecuentes' ? '' : 'ocultar'"
        >
          <div>
            <h3 class="titulo-borde-izq">Preguntas frecuentes</h3>
            <div class="lista_menu_bd">
              <ul>
                <li>
                  <ul>
                    <a href="#AvanceNacional"><li>Avance Entidad</li></a>
                  </ul>
                </li>
                <li>
                  <ul>
                    <a href="#ConsultaVotacion"><li>Consulta de la votación</li></a>
                  </ul>
                </li>
                <li>
                  <ul>
                    <a href="#ConoceCasilla"><li>Conoce los resultados de tu Casilla</li></a>
                  </ul>
                </li>
                <li>
                  <ul>
                    <a href="ActasContabilizadas"><li>Votos en Actas Contabilizadas</li></a>
                  </ul>
                </li>
                <li>
                  <ul>
                    <a href="#VisualizacionTarjetas"><li>Visualización tarjetas</li></a>
                  </ul>
                </li>
                <li>
                  <ul>
                    <a href="#VisualizacionGraficos"><li>Visualización gráficos</li></a>
                  </ul>
                </li>
                <li>
                  <ul>
                    <a href="#VerDetalle"><li>Ver Detalle</li></a>
                  </ul>
                </li>
                <li>
                  <ul>
                    <a href="#ResumenVotacion"><li>Resumen de la votación</li></a>
                  </ul>
                </li>
                <li>
                  <ul>
                    <a href="#Estadistica"><li>Estadística</li></a>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="row mt-5">
          <div class="col-12 mt-4">
            <button class="boton-regresar" @click="regresar">
              <font-awesome-icon icon="arrow-left" />
              <span>
                Regresar
              </span>
            </button>
          </div>
        </div>
      </div>
      <button class="boton-ocultar" @click="manejarOcultar">
        <font-awesome-icon icon="chevron-left" v-if="!colapsado" />
        <font-awesome-icon icon="chevron-right" v-else />
        <span>
          <span class="ocultarBtn" v-if="!colapsado">
            Ocultar
          </span>
          <span class="ocultarBtn" v-else>
            Mostrar
          </span>
          <span>Menú</span>
        </span>
      </button>
    </div>
    <div class="ventana-contenido pb-0">

      <CabeceraContenido
        :avance="avance"
        @mostrarModal="mostrarModal"
      />

      <div id="contenido-principal">
        <slot />
      </div>
    </div>
    <AvisoPrivacidad :escritorio="true" :seccion="seccionInput" />
    <ConocePorcentajeParticipacionCiudadana :escritorio="true" />
    <ConoceTuCredencial />
  </div>
</template>

<script>
import AvisoPrivacidad from '@/components/Movil/componentes/AvisoPrivacidad.vue';
import ConoceTuCredencial from '@/components/Escritorio/componentes/ConoceTuCredencial.vue';
import CabeceraContenido from '@/components/Escritorio/componentes/CabeceraContenido.vue';
import ConocePorcentajeParticipacionCiudadana from '@/components/ConocePorcentajeParticipacionCiudadana.vue';

export default {
  components: {
    AvisoPrivacidad,
    ConoceTuCredencial,
    ConocePorcentajeParticipacionCiudadana,
    CabeceraContenido,
  },
  data() {
    return {
      seccion: this.$route.params.seccion ?? '',
      distrito: this.$route.params.distrito ?? null,
      casilla: this.$route.params.casilla ?? null,
      esVotoEnElExtranjero: false,
      colapsado: false,
      seccionInput: null,
    };
  },
  created() {
    if (this.$router.currentRoute.name === 'Secciones' && !this.$route.params.seccion) {
      this.seccion = 0;
    }

    this.esVotoEnElExtranjero = this.$router.currentRoute.name === 'RepresentacionProporcional'
      || this.$router.currentRoute.name === 'VotoEnElExtranjero';
  },
  computed: {
    eleccion() {
      return this.$route.params.eleccion;
    },
    regiones() {
      return this.$route.params.eleccion === 'A'
        ? this.$store.state.regiones.municipios
        : this.$store.state.elementosGenerales.datos_distritos;
    },
    getURL() {
      return this.$route.name;
    },
    esSeccion() {
      return (
        this.$router.currentRoute.name === 'DetalleSeccion'
        || this.$router.currentRoute.name === 'Secciones'
        || this.$router.currentRoute.name === 'SeccionCasilla'
      );
    },
    esDetallePorCasilla() {
      return this.$router.currentRoute.name === 'DetallePorCasilla';
    },
    esVotoPorCandidatura() {
      return this.$router.currentRoute.path.includes('VPC');
    },
    esVotoPorPartido() {
      return this.$router.currentRoute.path.includes('VPP');
    },
    avance() {
      if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dAvance;
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aAvance;
      }
      return this.$store.state.gAvance;
    },
    elementosGenerales() {
      return this.$store.state.elementosGenerales;
    },
    listaDistritos() {
      return this.$store.state.elementosGenerales.datos_distritos;
    },
    listaMunicipios() {
      return this.$store.state.elementosGenerales.datos_municipios;
    },
    listaSecciones() {
      if (this.$route.params.eleccion === 'G' || this.$route.params.eleccion === 'D') {
        return this.$store.state.elementosGenerales.datos_secciones
          .distritos[this.distrito - 1]?.datos_secciones;
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.elementosGenerales
          .datos_secciones_por_municipio[this.distrito - 1]?.datos_secciones;
      }

      return {};
    },
    listaCasillas() {
      return this.$store.state.elementosGenerales
        .datos_casillas
        .filter((seccion) => parseInt(seccion.seccion_id, 10) === parseInt(this.seccion, 10))[0];
    },
  },
  methods: {
    obtenerRuteDetalleCasilla() {
      if (this.$router.currentRoute.name === 'VotosPorCandidaturas') {
        return `/${this.eleccion}/DET/VPC/1`;
      }
      return '#';
    },
    irADetalleSeccion() {
      if (this.seccion === '') {
        this.$router.push(`/${this.eleccion}/DET/VPC/${this.distrito}`);
      } else if (this.seccion === '0') {
        this.$router.push(`/${this.eleccion}/Secciones/${this.distrito}`);
      } else {
        this.$router.push(`/${this.eleccion}/Secciones/${this.distrito}/${this.seccion}`);
      }
    },
    cambiarVista() {
      const { name } = this.$router.currentRoute;
      const {
        eleccion, seccion, casilla,
      } = this.$route.params;

      const rutas = {
        VotosPorCandidaturas: `/${eleccion}/DET/VPC/${this.distrito}`,
        DetalleDelDistrito: `/${eleccion}/DET/VPC/${this.distrito}`,
        DetalleDelDistritoySeccion: `/${eleccion}/DET/VPC/${this.distrito}/${seccion}`,
        DetalleDelDistritoySeccionVPP: `/${eleccion}/DET/VPP/${this.distrito}/${seccion}`,
        DetalleDeCasilla: `/${eleccion}/DET/VPC/${this.distrito}/${seccion}/${casilla}`,
        VotosPorPartidoPolitico: `/${eleccion}/DET/VPP/${this.distrito}`,
        DetalleDelDistritoVPP: `/${eleccion}/DET/VPP/${this.distrito}`,
        DetallePorCasilla: `/${eleccion}/DPC/${this.distrito}`,
      };

      if (!this.distrito) {
        this.$router.push(`/${eleccion}/ENT/VPC/`);
        return null;
      }

      this.$router.push(rutas[name]);
      return null;
    },
    irADetalleCasilla() {
      this.$router.push(
        `/${this.eleccion}/Secciones/${this.distrito}/${this.seccion}/${this.casilla}`,
      );
    },
    manejarOcultar() {
      this.colapsado = !this.colapsado;
    },
    regresar() {
      let { eleccion } = this.$route.params;

      eleccion = !eleccion ? 'D' : eleccion;

      this.$router.push(`/${eleccion}/ENT/VPC`);
    },
    mostrarModal(seccion) {
      this.seccionInput = seccion;
      this.$bvModal.show('AvisoPrivacidadMovilModal');
    },
  },
  watch: {
    casilla: {
      handler(val) {
        if (!val && this.$router.currentRoute.name === 'SeccionCasilla') {
          this.$router.push(`/${this.eleccion}/Secciones/${this.distrito}/${this.seccion}`);
        }
      },
    },
    distrito: {
      handler(val, old) {
        if (!val) {
          const { eleccion } = this.$route.params;
          this.$router.push(`/${eleccion}/ENT/VPC`);
        } else if (val !== old) {
          if (
            this.$router.currentRoute.name === 'Secciones'
            || this.$router.currentRoute.name === 'DetalleSeccion'
            || this.$router.currentRoute.name === 'SeccionCasilla'
          ) {
            const { eleccion } = this.$route.params;

            this.$router.push({
              name: 'Secciones',
              params: {
                eleccion,
                distrito: this.distrito,
              },
            });
          }
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.icono {
  &:hover {
    cursor: pointer;
  }
}

.boton-regresar {
  display: block;
  margin: 0 auto;
  /* width: 100%; */
  margin-top: 4rem;
  width: 200px;
  padding: 0.7rem;
  border-radius: 7.5px;
  font-weight: 500;
  border: 1px solid black;
  background-color: #F4F4F4;
  text-align: center;

  span {
    margin-left: 10px;
  }
}

</style>
