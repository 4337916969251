<template>
  <b-modal
    id="ConocePorcentajeParticipacionCiudadana"
    centered
    no-close-on-backdrop
    no-close-on-esc
    title="Conoce cómo calcular el porcentaje de Participación ciudadana"
    hide-footer
  >
    <div>
    <p>
      La Participación ciudadana es el porcentaje de ciudadanos que votaron, con base en la Lista Nominal de las Actas Contabilizadas.
    </p>

    <p>
      Por ejemplo, si en una casilla votan 500 electores y el número de personas inscritas en la Lista Nominal para esa casilla es de 750 ciudadanos, entonces:
    </p>

    <p>
      Multiplicamos 500 por 100 y el resultado lo dividimos entre 750.
    </p>

    <p>
      La votación emitida en las Casillas Especiales se tomará en cuenta para el cálculo de la Participación ciudadana, hasta el último corte de la Participación ciudadana, hasta el último corte de información que se publique en el PREP.
    </p>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: 'ConocePorcentajeParticipacionCiudadana',
  props: {
    escritorio: {
      type: Boolean,
      default: () => true
    }
  },
}
</script>
<style lang="scss" scoped>

</style>
