<template>
  <b-modal
    id="ConoceTuCredencial"
    centered
    no-close-on-backdrop
    no-close-on-esc
    title="Identifica la Sección y Primer apellido de acuerdo a tu credencial"
    hide-footer
    size="lg"
  >
    <div class="row">
      <div class="col-6">
        <img src="@/assets/resultadoscasilla/Credencial-A.svg" alt="" class="img-fluid d-block mx-auto">
      </div>
      <div class="col-6">
        <img src="@/assets/resultadoscasilla/Credencial-B.svg" alt="" class="img-fluid d-block mx-auto">
      </div>
      <div class="col-6">
        <img src="@/assets/resultadoscasilla/Credencial-C.svg" alt="" class="img-fluid d-block mx-auto">
      </div>
      <div class="col-6">
        <img src="@/assets/resultadoscasilla/Credencial-D.svg" alt="" class="img-fluid d-block mx-auto">
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ConoceTuCredencial'
}
</script>
